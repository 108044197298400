import { createContext, useContext, useEffect, useRef, useState } from 'react';

import type { ProductType } from '@stories/organisms/HitListView/ReduceProps';
import type { TProductES } from '@vitafy/common-schemas';
import type { ReactNode } from 'react';

/**
 * A context to manage the state of a product with options
 *
 * productOptions are the different options a product can have.
 * For example a configurable can have different flavors. A bundle
 * can have an option for each item in the bundle.
 *
 * productConfiguration is the current configuration of the product
 * (i.e. the selected options). It must be defined before adding to the cart.
 */

export type TProductOption = Omit<NonNullable<TProductES['product_options']>[number], 'selection'> & {
  selection: Array<
    NonNullable<TProductES['product_options']>[number]['selection'][number] & {
      selected?: boolean;
    }
  >;
};
export type TProductOptions = TProductOption[];

export type TProductConfiguration = {
  [id: number]: number;
};

export type TProductWithOptionsContextUtils = {
  selectOption: (option: { productOptionId: number; selectedOptionId: number }) => void;
  isProductConfigured: boolean;
  productConfiguration: TProductConfiguration | null;
  productOptionsAvailable: boolean;
};

export type TProductWithOptionsContextValues = {
  baseprice: string;
  name: string;
  price: string;
  productId: string;
  productImage: string;
  productOptions: TProductOptions;
  productType?: ProductType | null;
};

export type TProductWithOptionsContext = TProductWithOptionsContextValues & TProductWithOptionsContextUtils;

export const ProductWithOptionsContext = createContext<TProductWithOptionsContext | undefined>(undefined);

export const hasProductOptions = (options: TProductOptions): boolean =>
  options?.length > 0 && options.some((option) => option.selection.length > 1);

export const validateProductConfiguration = (options: TProductOptions): boolean =>
  options.every(
    ({ selection }) => selection.length === 1 || selection.findIndex(({ selected }) => selected === true) > -1
  );

export const getProductConfiguartion = (productOptions: TProductOptions): TProductConfiguration | null => {
  if (!validateProductConfiguration(productOptions)) {
    return null;
  }

  return productOptions.reduce(
    (acc, { id, selection }) =>
      selection.length === 1
        ? { ...acc, [id]: selection[0].id }
        : { ...acc, [id]: selection.find(({ selected }) => selected === true)?.id },
    {}
  );
};

export const getUpdatedOptions = ({ productOptions, productOptionId, selectedOptionId }) =>
  productOptions?.map((productOption) => {
    if (productOption.id === productOptionId) {
      return {
        ...productOption,
        selection: productOption?.selection?.map((selectOption) => ({
          ...selectOption,
          selected: selectOption.id === selectedOptionId
        }))
      };
    }
    return productOption;
  });

export const selectFirstInStockForUncofigured = (productOptions: TProductOptions): TProductOptions => {
  const productOptionsWithSelectAttribute = productOptions?.map((productOption) => {
    const selectedIndex = productOption.selection.findIndex((selectOption) => selectOption.selected === true);
    if (selectedIndex > -1) {
      return productOption;
    }

    const firstInStockIndex = productOption.selection.findIndex(
      (selectOption) => selectOption.is_in_stock === true
    );

    return {
      ...productOption,
      selection: productOption.selection.map((selectOption, idx) => ({
        ...selectOption,
        selected: idx === firstInStockIndex ? true : false
      }))
    };
  });

  return productOptionsWithSelectAttribute;
};

// Return the list of products in the options. Some bundles have the same product in different options,
// but we want to show it only once, e.g. in the details
export const getUniqueOptions = (productOptions: TProductOptions): TProductOptions => {
  return productOptions.reduce((acc, option) => {
    const productId = option.selection[0].product_id;
    if (!acc.some((opt) => opt.selection[0].product_id === productId)) {
      acc.push(option);
    }
    return acc;
  }, [] as TProductOptions);
};

// Remove optional bundle items.
// TODO They should be rendered differently than flavor options
// Example: BL /doppelpack-whey-protein-2-x-2000g.html
export const getOptionsWithoutAddOns = (productOptions: TProductOptions): TProductOptions =>
  productOptions.filter(({ title }) => !title?.startsWith('Option'));

export const getAlphabeticallySortedOptions = (productOptions: TProductOptions): TProductOptions =>
  productOptions.map((productOption) => ({
    ...productOption,
    selection: productOption.selection
      .map((option) =>
        option.is_in_stock ? option : { ...option, title: `${option.title} - leider ausverkauft` }
      )
      .sort((a, b) => (a.title || '').localeCompare(b.title || ''))
  }));

export const useProductWithOptionsContext = (): TProductWithOptionsContext => {
  const context = useContext(ProductWithOptionsContext);
  if (typeof context === 'undefined') {
    throw new Error('useProductWithOptionsContext must be used within a ProductWithOptionsContext');
  }

  return context;
};

type TProductWithOptionsContextProvider = Omit<TProductWithOptionsContextValues, 'productOptions'> & {
  productOptions?: TProductOptions | null;
} & { children: ReactNode };

export const getOptionsWithDefault = (productOptions: TProductOptions): TProductOptions =>
  selectFirstInStockForUncofigured(getAlphabeticallySortedOptions(getOptionsWithoutAddOns(productOptions)));

export const ProductWithOptionsContextProvider: React.FC<TProductWithOptionsContextProvider> = ({
  children,
  productOptions: initialProductOptions,
  ...valuesRest
}) => {
  const firstRender = useRef(true);
  const [productOptions, setProductOptions] = useState<TProductOptions>(
    getOptionsWithDefault(initialProductOptions || [])
  );
  const isProductConfigured = validateProductConfiguration(productOptions);
  const productConfiguration = getProductConfiguartion(productOptions);
  const productOptionsAvailable = hasProductOptions(productOptions);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    setProductOptions(getOptionsWithDefault(initialProductOptions || []));
  }, [initialProductOptions]);

  const selectOption = ({ productOptionId, selectedOptionId }) =>
    setProductOptions(getUpdatedOptions({ productOptions, productOptionId, selectedOptionId }));

  return (
    <ProductWithOptionsContext.Provider
      value={{
        productOptions,
        ...valuesRest,
        selectOption,
        isProductConfigured,
        productConfiguration,
        productOptionsAvailable
      }}
    >
      {children}
    </ProductWithOptionsContext.Provider>
  );
};
